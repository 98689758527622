import React, { useState } from 'react';
import IND from '../../image/India.jpg';
import OMR from '../../image/om.png';
import AED from '../../image/ae.png';
import LOGO from '../../image/logo.png'

const Header = () => {
  const [currency, setCurrency] = useState('INR');
  const [currencyImg, setCurrencyImg] = useState(IND);
  const Currency = [
    {
      item: 1,
      name: 'INR',
      image: IND
    },{
      item: 2,
      name: 'OMR',
      image: OMR
    },{
      item: 3,
      name: 'AED',
      image: AED
    }
  ];

  const selectCountry = (cur, curId, curImg) => {
    setCurrency(cur);
    setCurrencyImg(curImg);
    window.localStorage.setItem('countryId', curId);
  };
return(
    <nav className="navbar navbar-expand-md navbar-dark fixed-top" id="banner">
	<div className="container">
  <a className="navbar-brand" href="#"><img src={LOGO} style={{width: '30%'}} alt="logo" /></a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="collapsibleNavbar">
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <a className="nav-link" href="#contact">Contact Us</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="Page2">PNR</a>
      </li>
      {/* <li className="nav-item">
        <a className="nav-link" href="#">Link</a>
      </li>  */}
    <li className="nav-item dropdown">
      <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
      <span><img className='flag' src={currencyImg} /></span>{currency}
      </a>
      <div className="dropdown-menu">
        {Currency.map(item => (
          <a className="dropdown-item"  onClick={() => { selectCountry(item.name, item.item, item.image); }}><span><img className='flag' src={item.image} /></span>{item.name}</a>
        )) 
        }
      </div>
    </li>
    </ul>
  </div>
	</div>
</nav>
)
};
export default Header;