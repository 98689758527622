import React from 'react';
import Logo from '../../image/logo.png';
import GreenCheck from '../../image/greenCheck.png'; 

const BillingDetails = () => {
    const TripData = JSON.parse(window.localStorage.getItem('tripDetails'));
    const PersonalData = JSON.parse(window.localStorage.getItem('personDetails'));
    const pickUpArray = TripData.pickUp.split(",");
    const dropArray = TripData.drop.split(",");
return (
    <div className="bg-form-billing">
        <div className="row m-0 justify-content-center">
            <img className='w20' src={Logo} alt="logo" />
        </div>
        <div className="row m-0 mt-5 justify-content-center">
            <img style={{width: 70}} src={GreenCheck} alt="check" />
        </div>
        <div className="row m-0 justify-content-center font16 fontwt400 font_fam2 mb-1 green">
            Email has been sent to registered emailId !!
        </div>
        <div className="row m-0 justify-content-center font16 fontwt400 font_fam2 mb-1">
        <div className="navbar-brand" style={{ fontSize: 42 }}><span style={{ fontSize: 42 }}>Thank You</span><br />for choosing US</div>
        </div>
        <center>
        <div class="confirm mt-5 col-12 col-md-8">
            <div className='row font16 fontwt400 font_fam1 m-0 justify-content-center'>
                Confirmed Booking Details
            </div>
        </div>
        <div class="col-12 col-md-8 border" style={{background: '#42429866'}}>
            <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>PNR ID</div>
                <div className='col-7 d-flex flex-wrap'>{window.localStorage.getItem('Pnr')}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Name</div>
                <div className='col-7 d-flex flex-wrap'>{PersonalData.name}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Email</div>
                <div className='col-7 d-flex flex-wrap'>{PersonalData.email}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Phone Number</div>
                <div className='col-7 d-flex flex-wrap'>{PersonalData.phoneNumber}</div>
                </div>
            <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Itenary</div>
                <div className='col-7 d-flex flex-wrap'>{pickUpArray[0]}<i class="icofont-long-arrow-right ml-1 mt-1"></i>{dropArray[0]}<i class="icofont-long-arrow-right ml-1 mt-1"></i>{pickUpArray[0]}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Start Date</div>
                <div className='col-7 d-flex flex-wrap'>{TripData.checkInDate}</div>
                </div>
                {TripData.checkOutDate && (<div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Drop Date</div>
                <div className='col-7 d-flex flex-wrap'>{TripData.checkOutDate}</div>
                </div>)}
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Pick Up Time</div>
                <div className='col-7 d-flex flex-wrap'>{TripData.time}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1 borderBottom">
                <div className='col-5 text-center'>Car</div>
                <div className='col-7 d-flex flex-wrap'>{window.localStorage.getItem('vehicleName')}</div>
                </div>
                <div className="row m-0 font16 fontwt400 mb-1">
                <div className='col-5 text-center'>Total</div>
                <div className='col-7 d-flex flex-wrap'>{window.localStorage.getItem('finalAmt')}</div>
                </div>
        </div>
        </center>
    </div>
)
};
export default BillingDetails;