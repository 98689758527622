/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Vehicle from './component/Vehicle';
import PersonalInformation from './component/PersonalInformation';
import BillingDetails from './component/BillingDetails';
import LocalVehicle from './component/LocalVehicle';
import Email from './component/Email';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";

$(document).on("scroll", function(){
  if
    ($(document).scrollTop() > 86){
    $("#banner").addClass("shrink");
  }
  else
  {
    $("#banner").removeClass("shrink");
  }
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
      });
  });
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="Vehicle" element={<Vehicle />} />
      <Route path="LocalVehicle" element={<LocalVehicle />} />
      <Route path="PersonalInformation" element={<PersonalInformation />} />
      <Route path="BillingDetails" element={<BillingDetails />} />
      <Route path="email" element={<Email />} />
      {/* <Route path="invoices" element={<Invoices />} /> */}
    </Routes>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
